import React from "react";
import Arrow from "../../shared/arrow";

import "./style.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure
} from '@chakra-ui/react';

const ProjectModal = ({ user, date, image, title, description }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className="readmore-cta" onClick={onOpen}>
                    <span className="text">Read More</span>
                    <Arrow />
    </div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="ModalBody">
            
            <div className="content-section">
                <div className="info-bar">
                    <div className="user-name"></div>
                    <div className="posted-date">{date}</div>
                </div>
                <div>{description}</div>
            </ div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}



export default ProjectModal;
