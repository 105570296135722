import React, { useState } from 'react';

import Section from "../shared/section";
import BlogCard from "./blog-card";
import ProjectModal from '../shared/Modal';
import Fruit from "../../images/blogs/Fruit.jpg";
import Management from"../../images/blogs/Management.jpg";
import Draw from"../../images/blogs/Draw.jpg";

import "./style.scss";

const projects = [
    <BlogCard
        user=""
        date="Dec 2023"
        image={Fruit}
        title="Fresh & Rotten Fruit Detection System (Graduate School Class Project)"
        description={
            <p>
                Work as a 3-person team member, developed a system for classifying fruits as fresh or rotten. <br /><br />
                This project utilized the Fruits - Fresh and Rotten for Classification dataset from Kaggle.<br /><br />
                The system leveraged cutting-edge machine learning models, with a primary focus on Convolutional Neural Networks (CNNs), including architectures like VGG16, VGG19, and ResNet50.<br /><br />
                The project involved intensive image preprocessing, model evaluation, and optimization, including cross-validation and application of data augmentation techniques.<br/><br />
                The CNN models underwent fine-tuning and validation, demonstrating remarkable classification accuracy. The project achieved a notable testing accuracy of 99.5% with the ResNet50 model.
            </p>

            }    
        />,
    <BlogCard
        user=""
        date="May 2023"
        image={Management}
        title="Management System (Senior Design Project)"
        description={
            <p>
                Worked as senior design project manager for a ticketing system with full function on creating/deleting tickets, assigned to individuals/groups.<br /><br />
                Powered by React, MongoDB, ExpressJs
            </p>
        }
        
    />,
    <BlogCard
        user=""
        date="2022"
        image={Draw}
        title="C++ Graphic Drawing"
        description={
            <p>
                Using Allegro5 on C++ to achieve an interface for drawing. Designed with multiple design patterns for the project. <br /><br />
                Provides the basic graphic user interface, and the application logic for the graphic editor. <br /><br />
                Achieved draw, select, group, delete, move, undo/redo, and export/import to/from text file functions.

            </p>
        }
        
        
    />
];


const Project = () => {
    const [currentProject, setcurrentProject] = useState(0);
    const changeProject = (direction) => {
        if (direction === 'left') {
            setcurrentProject(currentProject > 0 ? currentProject - 1 : projects.length - 1);
        } else {
            setcurrentProject(currentProject < projects.length - 1 ? currentProject + 1 : 0);
        }
    };
    return (
        <Section
            id="Projects"
            title="Blogs & Articles"
            background="dark"
        >
            <div className="blogs-content-wrapper">
                
                 <div className="gallery-arrow" onClick={() => changeProject('left')}><div class="arrow"/></div>
                    <div className="gallery-project">
                        {projects[currentProject]}
                    </div>

                    <div className="gallery-arrow" onClick={() => changeProject('right')}><div class="arrow arrow-right"/></div>
            </div>
        </Section>
    );
};

export default Project;
