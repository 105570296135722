import React from "react";


import "./style.scss";

const Showcase = ({ data= [], transition }) => {
    
    return (
        <div className="projects-showcase">
            {data.map((project) => (
                
                <div
                    className={`content ${
                        transition === "zoomout"
                            ? "zoomOut"
                            : transition === "zoomin"
                            ? "zoomIn"
                            : ""
                    }`}
                    
                >
                    
                    {project.content}
                    
                </div>
            ))}
        </div>
    );
};

export default Showcase;
