import React from "react";

import Section from "../shared/section";

import { scrollToSection } from "../utils/helpers";
import Clock from "./Clock";
import "./style.scss";

const Footer = () => {
    return (
        <Section
            background="dark"
            className="footer"
        >
            <div className="footer-content-wrapper">
                <ul className="footer-menu-items">
                    <li
                        className="footer-menu-item"
                        onClick={() => scrollToSection("About")}
                    >
                        About
                    </li>
                    
                    <li
                        className="footer-menu-item"
                        onClick={() => scrollToSection("Projects")}
                    >
                        Projects
                    </li>
                    <li
                        className="footer-menu-item"
                        action={() => {
                            scrollToSection("contact");
                        }}
                    >
                        Contact me
                    </li>
                </ul>

                <div className="bottom-bar">
                    <div className="copyright-text">
                        {/* Copyright 2022 JS Dev Hindi | All Rights Reserved */}
                        <Clock />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Footer;
