import React, { useState } from "react";

import Section from "../shared/section";
import Filters from "./filters";
import Showcase from "./showcase";
import HeadShot from "./About.jpg"

import { AiOutlineCloudDownload } from "react-icons/ai";
import CallToAction from "../shared/CallToAction";
import "./style.scss";




const DownloadLink = () => {
  const fileUrl = 'https://drive.google.com/file/d/1lunAPrPNwq1jM54HACEmxveYGB7wTbQA/view?usp=sharing'; 
  return (
    <div>
      <a href={fileUrl} download >
        <CallToAction
                        text="Download CV"
                        icon={<AiOutlineCloudDownload />}
                        // action={() => window.open(Resume)}
        />
      </a>
    </div>
  );
}


const projectsData = [
    {
        id: 1,
        name: "Main",
        tags: ["main"],
        content:(<div className="content">
            <div className="main">
                <div className="Head"><img src={HeadShot} /></div>
                
                <div className="text">
                    <p>
                        A computer science student at UConn with a passion for programming. Throughout my time learning, I have taken my education out of the classroom, self-educate for Web-Development skills.
                    </p>
                    <p>Currently, I am looking for a software/web development, machine learing and data analysis internship.   </p>

                    <DownloadLink />
                </div>
            </div>
            
                </div>),
    },
    {
        id: 2,
        name: "Skills",
        tags: ["Skills"],
        content:(<div className="content">
                <div className="skills">
                    <div>
                        <h4>Programming Languages :&nbsp;&nbsp;&nbsp;</h4>
                        <p>Python, javascript, C++.</p>
                    </div>
                

                    <div >
                        <h4>Machine Learning :&nbsp;&nbsp;&nbsp;</h4>
                        <p>Applying Convolutional Neural Networks (CNNs) for image classification tasks.</p>
                    </div>
    
                    <div >
                        <h4>Software Development :&nbsp;&nbsp;&nbsp;</h4>
                        <p>Experienced in developing applications using Kotlin.</p>
                    </div>
    
                    <div >
                        <h4>Database Management :&nbsp;&nbsp;&nbsp;</h4>
                        <p>Skilled in SQL and MongoDB for database design and management.</p>
                    </div>

                    <div >
                        <h4>Web Development :&nbsp;&nbsp;&nbsp;</h4>
                        <p>Experienced in React for front-end web development.</p>
                    </div>
                </div>

            </div>),
    },
    {
        id: 3,
        name: "Education",
        tags: ["Education"],
        
        content:(<div className="content">

            <div className="education">
                <div>
                    <h4>MS in Computer Science, 2023 - Present</h4>
                    <p>University of Connecticut</p>
                    <p> GPA: 3.67/4.00</p>
                </div>
                <div >
                    <h4>BS in Computer Science, 2019-2023</h4>
                    <p>University of Connecticut</p>
                    <p>Honor: Babbidge Scholar</p>
                    <p> GPA: 3.65/4.00</p>
                </div>
            </div>

        </div>),
    },
    {
        id: 4,
        name: "Work Experience",
        tags: ["Work_Experience"],
        
        content:(<div className="content">
            <div className="work">
                <div>
                    <h4>Graduate Assistant, Aug. 2023 - Present	</h4>
                    <p>School of Engineering, University of Connecticut, Storrs, CT</p>
                    <p>✾ Help professor to teach, tutor, and grading course-related material on CSE 4701: Principles of Databases</p>
                </div>   
                <div>
                    <h4>Network Engineer Student Assistant, July 2021 - Present</h4>
                    <p>TSC, University of Connecticut, Storrs, CT</p>
                    <p>✾ Recording, tracking, and reporting network problems, securing work environment and equipment.</p>
                    <p>✾ Help update, modify, and make AutoCAD files with University building information.</p>
                </div>               
                <div>
                    <h4>Tech Support Center Advanced Student Employee, Sept. 2021 - May 2023 </h4>
                    <p>TSC, University of Connecticut, Storrs, CT</p>
                    <p>✾ Troubleshooting over the phone and in person, support to account, computers, classroom, and more.</p>
                    <p>✾ Working with Confluence/JIRA</p>
                </div>
                
            </div>            
                </div>),
    }
];
const DefaultData = [{
        id: 1,
        name: "Main",
        tags: ["main"],
        content:(<div className="content">
            <div className="main">
                <div className="Head"><img src={HeadShot} /></div>
                
                <div className="text">
                    <p>
                        A computer science student at UConn with a passion for programming. Throughout my time learning, I have taken my education out of the classroom, self-educate for Web-Development skills.
                    </p>
                    <p>Currently, I am looking for a software/web development, machine learing and data analysis internship.   </p>

                    <DownloadLink />

                </div>
            </div>
            
                </div>),
    }]

const About = () => {
    
    const [projects, setProjects] = useState(DefaultData);
    const [transition, setTransition] = useState(false);
    
    const filterProjects = (tag) => {
        setTransition("zoomout");

        setTimeout(() => {
            if (tag !== "") {
                const filteredProjects = projectsData.filter((f) =>
                    f.tags.includes(tag)
                );
                setProjects(filteredProjects);
                
            } else {
                setProjects(projectsData);

            }
            setTransition("zoomin");
        }, 200);

        setTimeout(() => {
            setTransition(false);
        }, 600);
    };

    return (
        <Section
            id="About"
            title="About"
            background="light"
        >
            <div className="portfolio-content-wrapper">
                <Filters filterProjects={(tag) => filterProjects(tag)} />
                <Showcase
                    data={projects}
                    transition={transition}
                />
            </div>
        </Section>
    );
};

export default About;
