import React from "react";

import "./App.scss";
import { ChakraProvider } from '@chakra-ui/react'


import Contact from "./components/contact";
import Footer from "./components/footer";
import Intro from "./components/intro";
import About from "./components/about";
import Project from "./components/project";

const App = () => {
  return (
    <ChakraProvider>
      <div>
        <Intro />
        <About />
        <Project />
        <Contact />
        <Footer />
      </div>
    </ChakraProvider>
  );
};

export default App;