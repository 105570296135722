
import Arrow from "../../shared/arrow";
import ProjectModal from "../../shared/Modal";
import "./style.scss";

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Input,
  Box,
  Text
} from '@chakra-ui/react';



const BlogCard = ({ user, date, image, title, description }) => {
    const handleOpenModal = () => {onOpen();};
    const { isOpen, onOpen, onClose } = useDisclosure();


    return (
        <div className="blog-card">

            <div className="image-section">
                <img
                    src={image}
                    alt={title}
                />
            </div>
            <div className="content-section">
                <div className="project-content">
                    <div className="info-bar">
                        <div className="user-name"></div>
                        <div className="posted-date">{date}</div>
                    </div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
                <ProjectModal user = {user} date={date}  title={title} description={description}/>
            </div>

            
        </div>
    );
};

export default BlogCard;
