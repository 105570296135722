import React, { useState } from "react";

import "./style.scss";

const filtersData = [
    {
        name: "Who am I",
        id: "main",
    },
    {
        name: "Education",
        id: "Education",
    },
    {
        name: "Skills",
        id: "Skills",
    },
    
    {
        name: "Work Experience",
        id: "Work_Experience",
    },
];

const Filters = ({ filterProjects }) => {
    const [active, setActive] = useState("main");
    const clickHandler = (id) => {

        setActive(id);
                console.log(id);

        filterProjects(id);

    };

    return (
        <ul className="filters-menu-items">
            {filtersData.map((item) => (
                <li
                    key={item.id}
                    className={`filter-menu-item ${
                        active === item.id ? "active" : ""
                    }`}
                    onClick={() => clickHandler(item.id)}
                >
                    {item.name}
                </li>
            ))}
        </ul>
    );
};

export default Filters;
