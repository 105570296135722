import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {FaGithub,FaLinkedinIn} from "react-icons/fa";
import SocialIcon from "./social-icon";
import "./style.scss";

const ContactInfo = () => {
    return (
        <div className="contact-info-box">
            <h4>
                I would be happy to answer any questions you may have! 
            </h4>

            <div className="bottom">
                <div className= "Text">
                    <div className="contact-option">
                        <FaPhoneAlt />
                        <span className="text">+1 201-724-6021</span>
                    </div>
                    <div className="contact-option">
                        <MdEmail />
                        <span className="text">rickywei1227@gmail.com</span>
                    </div>
                </div>
                <div className="social-icons">

                    <SocialIcon
                        color="#0D2636"
                        icon={<FaGithub />}
                        link = "https://github.com/RickyW-i"
                    />
                    <SocialIcon
                        color="#0A66C2"
                        icon={<FaLinkedinIn />}
                        link = "www.linkedin.com/in/ruiqi-wei"
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
